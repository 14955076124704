exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-e-2-grow-js": () => import("./../../../src/pages/e2grow.js" /* webpackChunkName: "component---src-pages-e-2-grow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-material-js": () => import("./../../../src/pages/material.js" /* webpackChunkName: "component---src-pages-material-js" */),
  "component---src-pages-nyheter-index-js": () => import("./../../../src/pages/nyheter/index.js" /* webpackChunkName: "component---src-pages-nyheter-index-js" */),
  "component---src-pages-nyheter-markdown-remark-frontmatter-id-js": () => import("./../../../src/pages/nyheter/{MarkdownRemark.frontmatter__id}.js" /* webpackChunkName: "component---src-pages-nyheter-markdown-remark-frontmatter-id-js" */)
}

