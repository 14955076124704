module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Styrkor.se","short_name":"Styrkor.se","start_url":"/","background_color":"TODO","theme_color":"TODO","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c8136313cda93bc0ea02eae236cd141e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://styrkor.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"24","matomoUrl":"https://matomo.accodeing.com","siteUrl":"https://styrkor.se"},
    },{
      plugin: require('../node_modules/@accodeing/gatsby-theme-heimr/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Styrkor.se","short_name":"Styrkor.se","background_color":"TODO","theme_color":"TODO"},"siteUrl":"https://styrkor.se","matomoSiteId":"24"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1182,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
